import React from 'react'
import { MoreInfo } from '@pcln/marketing'
import { ContentfulRichText } from '@pcln/contentful-components'
import type { ModalContent } from '@/types'

interface MoreInfoProps extends ModalContent {
  isOpen: boolean
  handleClose: () => void
  isFullHeight: boolean
}

function MoreInfoModal({
  heading,
  paragraph,
  body,
  disclaimer,
  primaryCallToAction,
  secondaryCallToAction,
  isOpen,
  handleClose,
  isFullHeight
}: MoreInfoProps) {
  const bodyContent = () => {
    if (body) {
      return body
    }
    if (paragraph) {
      return <ContentfulRichText body={paragraph} />
    }
    return null
  }
  const modalProps = {
    heading,
    isFullHeight,
    body: bodyContent(),
    disclaimer: disclaimer ? <ContentfulRichText body={disclaimer} /> : null,
    primaryCallToAction,
    secondaryCallToAction,
    isOpen,
    onClose: handleClose,
    withModal: true,
    enableOverflow: true,
    disableCloseButton: true
  }

  return <MoreInfo {...modalProps} />
}

export default MoreInfoModal
